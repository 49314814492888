import { BrowserRouter as Router, Route } from "react-router-dom";
import React from 'react';
import './App.css';

import Index from "./components/01_Inicio/Index";

import Manana from "./components/02_Manana/Index";

import Pasado from "./components/03_Pasado/Index";

import Carteleria from './components/04_Carteleria/Index'


function App() {
  return (
    <Router>
      
        <Route path='/' exact component={Index} / >

        <Route path='/manana' exact component={Manana} / >

        <Route path='/pasado' exact component={Pasado} / >

        <Route path='/carteleria' exact component={Carteleria} />

    </Router>
  );
}

export default App;
