const stateApi = 'D'

let rutaInicial = ''

export const credencialesNav = {
    username: 'FarmBragadoAPIDev',
    password: 'FarmBragadoAPIDev!'
}

// if(stateApi === 'D'){
//     rutaInicial= 'http://localhost:3001/api_v1'
// }

if(stateApi === 'D'){
    rutaInicial= 'https://api.farmaciasbragado.com.ar/api_v1'
}


//Obtiene las Farmacias que estan de Guardia Hoy
export const get_FarmaciaDeGuardia = `${rutaInicial}/guardias/getToday`

//Obtiene las Farmacias que estan de Guardia Mañana
export const get_FarmaciaDeGuardiaManana = `${rutaInicial}/guardias/getManana`

//Obtiene las Farmacias que estan de Guardia Pasado
export const get_FarmaciaDeGuardiaPasado = `${rutaInicial}/guardias/getPasado`
