
import Header from "./01_Header";
import BarraNavegacion from "./02_Navegacion";

const Navegacion = (props) => {
    return(
        <div>
            <Header data={props.data} />

            <BarraNavegacion data={props.data} dia={props.dia} />

        </div>
    )    
}


export default Navegacion
