import React, {useEffect, useState} from "react";
import axios from "axios";

import { 
    get_FarmaciaDeGuardiaPasado, 
    credencialesNav 
} from "../../services/services";

import Data from "../../locale/IndexProps.json";

import Navegacion from "../00_navegacion/Navegacion";
import Guardias from "../00_CardGuardias/00_CardGuardias";

import Loading from "../00_loading/loading";

import Errors from "../00_Errors/Index";
import ErrorProps from "../../locale/ErrorsProps.json";

const datosIniciales = async () => {
    try {
        const dataNextFarmaciaGuardia = await axios({
            method:'GET',
            url:`${get_FarmaciaDeGuardiaPasado}`,
            auth:{
                username: credencialesNav.username,
                password: credencialesNav.password
            },
        })
    
        return {
            conectApi: true,
            ProximasGuardias: dataNextFarmaciaGuardia.data.data
        }

    } catch (error) {
        return {
            conectApi: false,
            ProximasGuardias: []
        }
    }

}


const Pasado = (props) => {

    const [nextFarmacias, setNextFarmacias] = useState([])
    const [conectApi, setConectApi] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getData() {
          let response = await datosIniciales()
          setNextFarmacias(response.ProximasGuardias)
          setConectApi(response.conectApi)
          setLoading(false)
        }
        getData()
    }, [])

    if(loading){
        return(
            <Loading />
        )
    }

    if(conectApi){
        return (
            <div className='container'>
                <Navegacion data={Data} dia='pasado' />
    
                <Guardias data={Data} Guardias={nextFarmacias} />
            </div>
        )
    }

    if(!conectApi){
        <Errors Api={conectApi} Data={ErrorProps} />
    }


}

export default Pasado