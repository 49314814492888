import { ContentTitulo } from './Estilos'
const Titulo = (props) => {
    return (
        <ContentTitulo>
            <p>FARMACIAS DE GUARDIA <span>HOY</span></p>
        </ContentTitulo>
     
    
    

    )
}

export default Titulo
