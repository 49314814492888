import styled from 'styled-components';

const Guardias = (props) => {


    return (
        <div className="container pb-4">
            <div className="row mt-4">
            {
                props.Guardias.map(guardia => (
                    <CardGuardia key={guardia.Id} className='col-md-4 mx-auto  col-12  '>

                        <ContentNombreGuardia>
                            <NombreGuardia>
                                {guardia.Farmacias.Nombre}
                            </NombreGuardia>   
                        </ContentNombreGuardia>


                        <div className='row'>
                            <div className='col-8'>
                                <ContentInfoGuardia>
                                    <TextoInfoGuardia>
                                        <IconosInfoGuardia className='bi bi-geo-alt-fill' />
                                        {guardia.Farmacias.Direccion}
                                    </TextoInfoGuardia>
                                    {
                                        props.Hoy === true &&
                                        <div>
                                            {
                                                guardia.TipoGuardia.Id === 1 &&
                                                <TextoInfoGuardia>
                                                <IconosInfoGuardia className='bi bi-clock-fill' />
                                                {props.data.MsgGuardia24}
                                                </TextoInfoGuardia>
                                            }
                                                                                {
                                                guardia.TipoGuardia.Id === 2 &&
                                                <TextoInfoGuardia>
                                                <IconosInfoGuardia className='bi bi-clock-fill' />
                                                {props.data.MsgGuardia16}
                                                </TextoInfoGuardia>
                                            }
                                            {
                                                guardia.TipoGuardia.Id === 3 &&
                                                <TextoInfoGuardia>
                                                <IconosInfoGuardia className='bi bi-clock-fill' />
                                                {props.data.MsgGuardia14}
                                                </TextoInfoGuardia>
                                            }
                                        </div>
                                        
                                    }
            
                                    <TextoInfoGuardia>
                                        <IconosInfoGuardia className='bi bi-telephone-fill' />
                                        {guardia.Farmacias.Telefono}
                                    </TextoInfoGuardia>

                                </ContentInfoGuardia>
                            </div>

                            <div className='col-4 align-self-end'>    
                                <ContentBotonDetallesFarmacia >
                                    <a href={guardia.Farmacias.Ubicacion} target='_blank'  rel="noreferrer">
                                        <BotonUbicacionFarmacia>
                                            {props.data.VerUbicacion}
                                        </BotonUbicacionFarmacia>
                                    </a>

                                    {/* <Link to={`/farmacia/${guardia.Farmacias.Id}`} className='w-100'>
                                        <BotonUbicacionFarmacia>
                                            {props.data.VerMasInfo}
                                        </BotonUbicacionFarmacia>
                                    </Link> */}
                                </ContentBotonDetallesFarmacia>

                            </div>
                        </div>
                    </CardGuardia>
                   
                ))
            }
            </div>
            <br />
            <br />
        </div>
        
    )
}

export default Guardias

const CardGuardia = styled.div`
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid var(--Color-Verde-Border);
    margin-bottom: 25px;
    box-shadow: 2px 15px 61px 0px rgba(5,13,39,0.34);
    padding: 10px;
    width:33%;
    margin: auto;
    @media (max-width: 480px) {
        width:100%;
        margin: 2%;
    }
`

const ContentNombreGuardia = styled.div`
    border-bottom: 1px solid var(--Color-Verde-Border);
    padding: 10px 30px ;
    width: 100%;
`

const NombreGuardia = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: .9rem;
    font-weight: bold;
`

const ContentInfoGuardia = styled.div`
    padding: 10px 20px ;

`

const TextoInfoGuardia = styled.p`
    font-family: var(--Pro-Text-Light);
    font-size: .75rem;
    margin-bottom: 2% !important ;
    padding: 5px 0px;
`

const IconosInfoGuardia = styled.i`
    color: green;
    padding: 5px;
`

const ContentBotonDetallesFarmacia  = styled.div`
    padding: 10px 0px;
    margin-right: 0% !important;
`


const BotonUbicacionFarmacia = styled.button`
    background-color: var(--Color-Verde-Button);
    color: var(--Color-Verde-Texto);
    font-family: var(--Pro-Text-Medium);
    font-style: normal;
    font-weight: 600;
    font-size: .7rem;
    border: none;
    width: 100% !important;
    border-radius: 20px;
    padding: 12px 12px ;
    text-align: center;
`