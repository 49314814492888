import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ErrorApi = (props) => {
    return (
        <ContentErrorApi>
            <ContentMensajeError>

                <TituloError> 
                    {props.data.Ups}
                </TituloError>
                <MensajeError>
                    {props.data.Msg}
                </MensajeError>
                <MensajeIntentalo>
                    {props.data.Intentalo}
                </MensajeIntentalo>

   
                <Link to='/'>
                    <BotonRecargar>
                        {props.data.Recargar}
                    </BotonRecargar>
                </Link>
      


            </ContentMensajeError>

        </ContentErrorApi>

    )
}

export default ErrorApi

const ContentErrorApi = styled.div`
    height: 100vh;
    width: 100%;
    background:linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%); 
    display: flex;
    align-items: center;
`

const ContentMensajeError = styled.div`
    width: 70%;
    margin: auto;
    height: 50vh;
`

const TituloError = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-weight: bold;
    font-style: normal;
    font-size: 3.5rem;
`

const MensajeError = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: .5rem !important;
`
const MensajeIntentalo = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-weight: bold;
    font-size: 1rem;
`

const BotonRecargar = styled.button`
    margin-top: 1rem;
    background: #00713B;
    padding: 20px 33px;
    border: none;
    border-radius: 12px;
    font-family: var(--Pro-Text-Regular);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;    
    line-height: 1rem;
    color: #fff;
`