
import styled from 'styled-components';

const Loading = (props) => {
    return (
        <ContentGralLoading>
            <ContentLoading>

                <div className="loading">
                    <div></div>
                    <div></div>
                </div>
                <TituloLoading>
                    Cargando
                </TituloLoading>

            </ContentLoading>

    
        </ContentGralLoading>
   
     
    
    

    )
}

export default Loading

const ContentGralLoading = styled.div`
    height: 100vh;
    width: 100%;
    background:linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%); 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const ContentLoading = styled.div`
    width: 70%;


`

const TituloLoading = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-weight: bold;
    font-style: normal;
    font-size: 1rem;
    color: #00713B;

`