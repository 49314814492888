import styled from 'styled-components';

import Logo from "../../assets/images/Logo.png";


const Header = (props) => {
    return (
        <ContentHeader>
            <div className='row'>
                <ContentLogoHeader className='col-4'>
                    <ImagenLogo>
                        <img src={Logo} alt='Farmacias Bragado' className='w-100' />
                    </ImagenLogo>
                </ContentLogoHeader>

                <ContentTituloHeader className='col-8 align-self-center'>
                    <TituloHeader>
                        {props.data.TituloHeader}
                    </TituloHeader>
                    <SubtituloHeader>
                        {props.data.SubtituloHeader}
                    </SubtituloHeader>
                </ContentTituloHeader>


            </div>
        </ContentHeader>

    )
}

export default Header

const ContentHeader = styled.div`
    padding: 15px 25px;
`

const ContentLogoHeader = styled.div`
    padding: 10px;
`

const ImagenLogo = styled.div`
    height: 90px;
    width: 90px;
    margin:auto;
    box-shadow: 2px 15px 61px 0px rgba(5,13,39,0.34);
    border-radius: 50%;
`

const ContentTituloHeader = styled.div`
    padding-left: 20px;
`

const TituloHeader = styled.p `
    font-family: var(--Pro-Text-SemiBold);
    color: #000;
    font-size: 1.1rem;
    line-height: normal;
    padding: 5px 0px;
    border-bottom: 1px solid #808080;
`

const SubtituloHeader = styled.p`
    font-family: var(--Pro-Text-Light);
    color: #808080;
    font-size: .8rem;
    padding: 5px 0px;
`