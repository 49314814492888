import React, {useEffect, useState} from "react";
import axios from "axios";

import {
    get_FarmaciaDeGuardia,
    credencialesNav
} from "../../services/services";


import Navegacion from "../00_navegacion/Navegacion";
import Guardias from "../00_CardGuardias/00_CardGuardias";
import Data from "../../locale/IndexProps.json";

import Loading from "../00_loading/loading";

import Errors from "../00_Errors/Index";
import ErrorProps from "../../locale/ErrorsProps.json";

const initDates = async() => {
    try {
        const dataFarmaciaGuardia = await axios({
            method:'GET',
            url:`${get_FarmaciaDeGuardia}`,
            auth:{
                username: credencialesNav.username,
                password: credencialesNav.password
            },
        })
        
        return {
            FarmaciasDeGuardia: dataFarmaciaGuardia.data.data,
            conectApi: true
        }
        
    } catch (error) {
       
        return {
            conectApi: false,
            FarmaciasDeGuardia: []
        }
    }
}


const Inicio = (props) => {

    const [farmaciaGuardia, setFarmaciaGuardia] = useState([])
    const [conectApi, setConectApi] = useState(false)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        async function getData() {
          let response = await initDates()
          setFarmaciaGuardia(response.FarmaciasDeGuardia)
          setConectApi(response.conectApi)
          setLoading(false)
        }

        getData()
    }, [])


    if(loading){
        return (
            <Loading />
        )
    }

    if(conectApi){
        return (
            <div className='container'>
                <Navegacion data={Data} dia='hoy' />
                <Guardias data={Data} Guardias={farmaciaGuardia} Hoy={true} />
            </div>
    
        )
    }else{
        return (
            <Errors Api={conectApi} Data={ErrorProps} />
        )
    }


}

export default Inicio