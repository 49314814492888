import React, {useEffect, useState} from "react";
import axios from "axios";

import {
    get_FarmaciaDeGuardia,
    credencialesNav
} from "../../services/services";


import Loading from "../00_loading/loading";

import { ContentCarteleria, ContentPage } from './Estilos'
import Titulo from './Titulo'
import FBInfo from './FBInfo'
import Guardias from './Guardia'

import Errors from "../00_Errors/Index";
import ErrorProps from "../../locale/ErrorsProps.json";

const initDates = async() => {
    try {
        const dataFarmaciaGuardia = await axios({
            method:'GET',
            url:`${get_FarmaciaDeGuardia}`,
            auth:{
                username: credencialesNav.username,
                password: credencialesNav.password
            },
        })
        
        return {
            FarmaciasDeGuardia: dataFarmaciaGuardia.data.data,
            conectApi: true
        }
        
    } catch (error) {
       
        return {
            conectApi: false,
            FarmaciasDeGuardia: []
        }
    }
}


const Carteleria = (props) => {

    const [farmaciaGuardia, setFarmaciaGuardia] = useState([])
    const [conectApi, setConectApi] = useState(false)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        async function getData() {
          let response = await initDates()
          setFarmaciaGuardia(response.FarmaciasDeGuardia)
          setConectApi(response.conectApi)
          setLoading(false)
        }

        getData()
    }, [])


    if(loading){
        return (
            <Loading />
        )
    }

    if(conectApi){
        return (
            <ContentCarteleria>
                <Titulo />

                <ContentPage className="row">
                    <FBInfo />
                    <div className="col-md-7 align-self-center">
                        <Guardias  Guardias={farmaciaGuardia}  />
                    </div>
                </ContentPage>

                
            </ContentCarteleria>
    
        )
    }else{
        return (
            <Errors Api={conectApi} Data={ErrorProps} />
        )
    }


}

export default Carteleria