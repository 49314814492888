import styled from 'styled-components';

export const ContentCarteleria = styled.div`
    background: linear-gradient(180deg, #EEFFF0 0%, #FFFFFF 87.5%, rgba(255, 255, 255, 0) 96.87%);
    height: 100vh;
    max-width: 100vh;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

`

export const ContentTitulo = styled.div`
    background-color: var(--Color-Verde-Texto);
    font-family: var(--Pro-Text-Regular);
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    &>p{
        color: var(--Color-White);
        letter-spacing: 1px;
        font-size: 110%;

        &>span{
            background-color: var(--Color-White);
            color: var(--Color-Verde-Texto);
            padding: 10px;
            border-radius: 10px;
        }
    }
`

export const ContentPage = styled.div`
    height: 80vh;
    width: 80%;
    margin: auto;
`

export const ContentFBInfo = styled.div`
    font-family: var(--Pro-Text-Regular);


    &>div.logo{


        &>div>img{
            width: 80%;
            margin-left: 20%;
            
        }

        &>div>p{
            font-size: 150%;
            font-weight: bold;
            color: var(--Color-Negro);
        }

        &>div>p.subtitulo{
            color: var(--Color-Grey-Hover);
            font-size: 95%;
            font-weight: normal;
        }
    }

    &>div.line{
        height: 1px;
        background-color: var(--Color-Verde-Texto);
        width: 80%;
        margin: 5% 10%;
    }
    &>div.qr-code{
        &>img{
            width: 40%;
            margin-left: 30%;
        }
    }

    &>div.qr-code-subtitulo{
        font-weight: bold;
        text-align: center;
        font-size: 110%;
    }
`

export const CardGuardia = styled.div`
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid var(--Color-Verde-Border);
    box-shadow: 2px 15px 61px 0px rgba(5,13,39,0.34);
    padding: 30px 10px;
    width: 100%;
    margin: 2% 0%;

`

export const ContentNombreGuardia = styled.div`
    border-bottom: 1px solid var(--Color-Verde-Border);
    padding: 10px 30px;
    width: 100%;
`

export const NombreGuardia = styled.p`
    font-family: var(--Pro-Text-Regular);
    font-size: 275%;
    font-weight: bold;
`

export const TextoInfoGuardia = styled.p`
    font-family: var(--Pro-Text-Light);
    font-size: 150%;
    margin: 2% 0 !important ;
    padding: 0px 30px ;
`

export const IconosInfoGuardia = styled.i`
    color: green;
    padding: 5px;
`
