import Logo from "../../assets/images/Logo.png";
import QRCode from "../../assets/images/qrCode.png";

import { ContentFBInfo } from './Estilos'

const FBInfo = (props) => {
    return (
        <ContentFBInfo className='col-md-5 align-self-center p-4'>
            <div className="row logo">
                <div className="col-md-5">
                    <img src={Logo} alt='Farmacias Bragado' />
                </div>
                <div className="col-md-7 align-self-center">
                    <p>Farmacias Bragado</p>
                    <p className="subtitulo">Información online sobre las farmacias de guardia</p>

                </div>
                
            </div>
            <div className="line"></div>
            <div className="qr-code-subtitulo">
                <p>Escaneá el QR y entrá a nuestra web</p>
            </div>
            <div className="qr-code">
                <img src={QRCode} alt='Farmacias Bragado'/>
            </div>


        </ContentFBInfo>
     
    
    

    )
}

export default FBInfo