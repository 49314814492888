import { CardGuardia, ContentNombreGuardia, NombreGuardia, TextoInfoGuardia, IconosInfoGuardia } from './Estilos'

const Guardias = (props) => {

    return (
        <>
            { props.Guardias.map(guardia => (
                <CardGuardia key={guardia.Id}>
                    <ContentNombreGuardia>
                        <NombreGuardia>
                            {guardia.Farmacias.Nombre}
                        </NombreGuardia>   
                    </ContentNombreGuardia>

                    <TextoInfoGuardia>
                        <IconosInfoGuardia className='bi bi-geo-alt-fill' />
                        {guardia.Farmacias.Direccion}
                    </TextoInfoGuardia>

                    <TextoInfoGuardia>
                        <IconosInfoGuardia className='bi bi-telephone-fill' />
                        {guardia.Farmacias.Telefono}
                    </TextoInfoGuardia>
                </CardGuardia>
            ))}
        </>
    )

}

export default Guardias


