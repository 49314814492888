
import ErrorApi from "./01_ErrorApi";

const Errors = (props) => {
    if(!props.Api){
        return (
            <ErrorApi data={props.Data} />
        )
    }
}

export default Errors