import styled from 'styled-components';
import { Link } from "react-router-dom";

const Navegacion = (props) => {
    return (
        <ContentNav>
            <div className="row">
                <ContentButtonNav className='col-4'>
                    <Link to='/'>
                        {
                            props.dia === 'hoy' ?
                            <ButtonNavActive>
                                {props.data.Hoy}
                            </ButtonNavActive>
                        :
                            <ButtonNav>
                                {props.data.Hoy}
                            </ButtonNav>

                        }
                    </Link>
                </ContentButtonNav>

                <ContentButtonNav className='col-4'>
                    <Link to='/manana'>
                    {
                        props.dia === 'manana' ?
                        <ButtonNavActive>
                            {props.data.Manana}
                        </ButtonNavActive>
                    :
                        <ButtonNav>
                            {props.data.Manana}
                        </ButtonNav>
                    }    
                    </Link>
                </ContentButtonNav>

                <ContentButtonNav className='col-4'>
                    <Link to='/pasado'>
                    {
                        props.dia === 'pasado' ?
                        <ButtonNavActive>
                            {props.data.Pasado}
                        </ButtonNavActive>
                    :
                        <ButtonNav>
                            {props.data.Pasado}
                        </ButtonNav>
                    } 
                    </Link>

                </ContentButtonNav>
            </div>

        </ContentNav>

    )
}

export default Navegacion

const ContentNav = styled.div`
    padding: 15px 25px;
`

const ContentButtonNav = styled.div`
    padding-left: 3px!important;
    padding-right: 3px!important;
`

const ButtonNavActive = styled.button`
    background-color: #fff;
    width: 100%;
    border: none;
    border-radius: 25px;
    font-size: .9rem;
    font-weight: bold;
    font-family: var(--Pro-Text-Medium);
    color: #000;
    padding: 5px;
    box-shadow: 2px 15px 61px 0px rgba(5,13,39,0.34);

`

const ButtonNav = styled.button`
    background-color: #dbdbdb;
    width: 100%;
    border: none;
    border-radius: 25px;
    font-size: .9rem;
    font-family: var(--Pro-Text-Medium);
    padding: 5px;
    box-shadow: 2px 15px 61px 0px rgba(5,13,39,0.34);
    color: #808080;

`